@import 'vendor/bootstrap/bootstrap';
@import '~uikit/src/scss/variables';
@import '~uikit/src/scss/uikit';
@import 'vendor/fontawesome/fontawesome';
@import 'vendor/slick-slider/slick',
      'vendor/slick-slider/slick-theme';

@import "variables/sizes",
        "variables/colors",
        "variables/fonts";

@import "global/global",
        "global/form/checkbox",
        "global/form/slider";
@import "layout/header",
        "layout/nav",
        "layout/footer";

@import "pages/home",
        "pages/product",
        "products/categories",
        "products/product-list";

@import 'checkout/cart';

.alert {
    margin-bottom: 0;
}

.pastel-3-border-bottom {
    border-bottom: 1px solid $pastel-3-accent;
}
